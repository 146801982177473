export interface CategoryNav {
    id: string
    name: string
    slug: string
    stagedProductCount: number
    custom?: Record<string, Array<Record<string, any>>>
    orderHint: string
    childCount: number
    active?: boolean
    url?: string
    productCount?: number
    ancestors?: Array<string>
    children?: Array<CategoryNav>
}

const findActiveCategory = (categories: Array<CategoryNav>): CategoryNav => {
    if (!categories?.length) {
        return null
    }
    return categories.find((item) => item.active)
}

const filterCategoryHasProduct = (categories: Array<CategoryNav>): Array<CategoryNav> => {
    if (!categories?.length) {
        return []
    }
    return categories.filter((item) => item.productCount > 0)
}

export const getCategoriesToShowOnSlideInCategoryPage = (category) => {
    if (category?.projectSpecificData?.children?.length)
        return category.projectSpecificData.children || []

    return category?.projectSpecificData?.parent?.children || []
}

export const getCategoriesToShowOnSlideInStorePage = (selectedCategory: CategoryNav, parentOfSelectedCategory: CategoryNav, categoryTree?: Array<CategoryNav>): Array<CategoryNav> => {
    const selectedCategoryHasChild = selectedCategory?.childCount > 0 || selectedCategory?.children?.length > 0
    const childOfSelectedCategoryHasProduct = Boolean(selectedCategory?.children?.find(item => item.productCount > 0))
    if (selectedCategoryHasChild && childOfSelectedCategoryHasProduct) {
        return filterCategoryHasProduct(selectedCategory.children)
    }

    if(!selectedCategory || !parentOfSelectedCategory?.children) {
        return filterCategoryHasProduct(categoryTree)
    }

    return filterCategoryHasProduct(parentOfSelectedCategory?.children)
}

export const findSelectedCategoryInStorePage = (categoryTree: Array<CategoryNav>, selectedCategoryId?: string) => {
    const rootActiveCategory = findActiveCategory(categoryTree)

    if (!rootActiveCategory) {
        return {
            selectedCategory: null,
            parentOfSelectedCategory: null,
        }
    }

    if (rootActiveCategory.id === selectedCategoryId) {
        return {
            selectedCategory: rootActiveCategory,
            parentOfSelectedCategory: null,
        }
    }

    const stack = [{ category: rootActiveCategory, parent: null }]
    while (stack.length > 0) {
        const { category, parent } = stack.pop()
        const childIsActive = findActiveCategory(category?.children || [])

        if (!childIsActive && category?.id === selectedCategoryId) {
            return {
                selectedCategory: category,
                parentOfSelectedCategory: parent,
            }
        }

        if (childIsActive) {
            stack.push({ category: childIsActive, parent: category })
        }

        for (const child of category?.children || []) {
            if (child !== childIsActive) {
                stack.push({ category: child, parent: category })
            }
        }
    }

    return {
        selectedCategory: null,
        parentOfSelectedCategory: null,
    }
}

export const getProductCount = (category, facetCategory) => {
    if (facetCategory) {
        return facetCategory[category.id] || 0;
    }

    return category.productCount;
};
