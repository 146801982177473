/* eslint-disable no-underscore-dangle */
import classNames from 'classnames';
import React, {useEffect, useRef} from 'react';

import {NarrowLeftIcon} from '../../../atoms/icon';
import {
    findSelectedCategoryInStorePage,
    getCategoriesToShowOnSlideInCategoryPage,
    getCategoriesToShowOnSlideInStorePage,
    getProductCount
} from '../SidebarMenu/categoryHelper';
import { useRouter } from "next/router";

type Props = {
    currentCategory: any;
    node: any;
    categoryTree?: any;
    facetCategory?: any;
    displaySidebarByResult: boolean
};

const FIRST_CATEGORY_LEVEL_VISIBLE = 1;
const OFFSET_FROM_FIRST_BADGE_TO_LEFT = 52;
const SlideMenu: React.FC<Props> = ({
    currentCategory,
    node,
    categoryTree,
    facetCategory,
    displaySidebarByResult
}) => {
    const { query } = useRouter();
    const categorySidebarQuery = query.categorySidebar;

    let categoriesToShowOnSlide = [];
    let selectedCategory;
    let parentOfSelectedCategory;
    const pathName = query?.path || ''

    if (node.nodeType === 'store' || displaySidebarByResult) {
        const {
            selectedCategory: storeSelectedCategory,
            parentOfSelectedCategory: storeParentOfSelectedCategory,
        } = findSelectedCategoryInStorePage(categoryTree, `${categorySidebarQuery}`);
        selectedCategory = storeSelectedCategory;
        parentOfSelectedCategory = storeParentOfSelectedCategory;
        categoriesToShowOnSlide = getCategoriesToShowOnSlideInStorePage(
            selectedCategory,
            parentOfSelectedCategory,
            categoryTree
        )
    } else if (node.nodeType === 'category') {
        selectedCategory = currentCategory;
        parentOfSelectedCategory = currentCategory?.projectSpecificData?.parent;
        categoriesToShowOnSlide = getCategoriesToShowOnSlideInCategoryPage(currentCategory);
    } else {
        categoriesToShowOnSlide = categoryTree;
    }

    const generatePathInStorePage = (category) => {
        if (category?.id) {
            return `${pathName}?categorySidebar=${category.id}`
        }
        return pathName
    }

    const getCategoryUrl = (category) => {
        if (node.nodeType == 'store' || displaySidebarByResult) {
            if (isCategorySelected(category.id)) {
                return generatePathInStorePage(parentOfSelectedCategory);
            }

            if (getProductCount(category, facetCategory) > 0) {
                return generatePathInStorePage(category)
            }

            return '#'
        }

        if (isCategorySelected(category.id)) {
            return parentOfSelectedCategory?.url || '#';
        }

        return getProductCount(category, facetCategory) > 0 ? category?.url : '#';
    };

    const isCategorySelected = (categoryId: string) => {
        if (node.nodeType === 'store' || displaySidebarByResult) {
            return categoryId === selectedCategory?.id;
        }

        return categoryId === selectedCategory?.categoryId;
    };


    const hrefOfBackButton = () => {
        if (node.nodeType === 'store' || displaySidebarByResult) {
            return generatePathInStorePage(parentOfSelectedCategory);
        }

        return parentOfSelectedCategory?.url || '#';
    };

    // from level down of FIRST_CATEGORY_LEVEL_VISIBLE
    const isNotAtCategoryLevelVisible =
        currentCategory?.depth > FIRST_CATEGORY_LEVEL_VISIBLE;
    const isShowBackButton =
        (node.nodeType === 'store' || displaySidebarByResult)
            ? Boolean(selectedCategory?.level >= FIRST_CATEGORY_LEVEL_VISIBLE)
            : Boolean(isNotAtCategoryLevelVisible && parentOfSelectedCategory);

    const slideMenuRef = useRef(null);
    useEffect(() => {
        const selectedBadgeElement = document.getElementById(
            `badge-${currentCategory.categoryId}`
        );
        const calculatedOffset =
            selectedBadgeElement?.offsetLeft > OFFSET_FROM_FIRST_BADGE_TO_LEFT
                ? selectedBadgeElement?.offsetLeft - OFFSET_FROM_FIRST_BADGE_TO_LEFT
                : 0;
        if (selectedBadgeElement) {
            slideMenuRef.current.scrollTo({
                left: calculatedOffset,
                behavior: 'smooth',
            });
        }
    }, [currentCategory.categoryId]);

    return (
        <div ref={slideMenuRef} className="slider-wrapper laptop:hidden">
            {/* Only show back button when selected category have parent category and depth > 1 */}
            {isShowBackButton && (
                <a href={hrefOfBackButton()}>
                    <div
                        className="mr-1 flex h-8 w-8 items-center justify-center rounded-full border border-black"
                    >
                        <NarrowLeftIcon width={16} height={16}/>
                    </div>
                </a>
            )}

            {categoriesToShowOnSlide?.length > 0 &&
                categoriesToShowOnSlide.map((item) => (
                    <a
                        key={item.id}
                        className="no-underline"
                        id={`badge-${item.id}`}
                        href={getCategoryUrl(item)}
                    >
                        <div
                            className={classNames(
                                'border border-black inline-block mb-3 whitespace-no-wrap font-medium px-4 py-2 m-0 flex items-center justify-center rounded-2xl text-sm leading-none h-8',
                                {
                                    'text-white bg-black': isCategorySelected(item.id),
                                    'text-black': !isCategorySelected(item.id),
                                }
                            )}
                        >
                            {item?.name}
                        </div>
                    </a>
                ))}
        </div>
    );
};

export default SlideMenu;
